import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import ChatDrawer from './components/ChatDrawer';
import ChatFab from './components/ChatFab';
import MessagePopup from './components/MessagePopup';
import TypingIndicator from './components/TypingIndicator';
import getConfigByClientId from './config';
import { TypingIndicatorWrapper } from './styles/ChatInterfaceStyles';

const ChatInterface = forwardRef(({ clientId }, ref) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [customization, setCustomization] = useState({});
  const [apiEndpoint, setApiEndpoint] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [warningTimeout, setWarningTimeout] = useState(null);
  const warningTimeoutRef = useRef(null);
  const [isTypingIndicatorVisible, setIsTypingIndicatorVisible] = useState(false);
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [showUnreadIndicator, setShowUnreadIndicator] = useState(false);

  useImperativeHandle(ref, () => ({
    openChatInterface: () => setOpen(true)
  }));

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getConfigByClientId(clientId);
        setCustomization(config.customization);
        setApiEndpoint(config.apiEndpoint);
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    };
    fetchConfig();
  }, [clientId]);

  useEffect(() => {
    if (open) {
      setMessages([
        {
          text: customization.welcomeMessage || "Hello there! 👋 It's nice to meet you! What brings you here today? Please use the navigation below or ask me anything about AIvoorImpact. ",
          sender: 'bot',
          type: 'welcome',
        },
      ]);
    }
  }, [open, customization]);

  useEffect(() => {
    startTypingAnimation();
  }, []);

  const startTypingAnimation = () => {
    setIsTypingIndicatorVisible(true);
    setTimeout(() => {
      setIsTypingIndicatorVisible(false);
      setShowPopupMessage(true);
      setTimeout(() => {
        if (!open) {
          setShowPopupMessage(false);
          setShowUnreadIndicator(true);
        }
      }, 10000);
    }, 2000);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setShowPopupMessage(false);
    setShowUnreadIndicator(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => setInputValue(event.target.value);
  const handleKeyPress = (event) => event.key === 'Enter' && handleSendMessage();

  const handlePopupClick = () => {
    setOpen(true);
    setShowPopupMessage(false);
    setShowUnreadIndicator(false);
  };

  const handleSendMessage = async () => {
    const messageText = inputValue.trim();
    if (messageText) {
      const newMessage = { text: messageText, sender: 'user', type: 'normal' };
      setMessages([...messages, newMessage]);
      setInputValue('');
      setIsTyping(true);
  
      const timeout = setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: "Het kan even duren voordat ik antwoord geef.",
            sender: 'bot',
            type: 'warning',
          },
        ]);
      }, 10000);
      setWarningTimeout(timeout);
      warningTimeoutRef.current = timeout;
  
      if (apiEndpoint === 'testing') {
        setTimeout(() => {
          const testingResponse = { text: `This is a testing response to: "${messageText}"`, sender: 'bot', type: 'normal' };
          setMessages((prevMessages) => {
            // Remove the warning message if it exists
            const filteredMessages = prevMessages.filter(msg => msg.type !== 'warning');
            return [...filteredMessages, testingResponse];
          });
          setIsTyping(false);
          clearTimeout(warningTimeoutRef.current);
        }, 1000);
      } else {
        try {
          const response = await fetch(apiEndpoint + 'generate_response', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              session_id: sessionId,
              message: messageText,
              role: selectedRole ? selectedRole.value : null,
              url: window.location.href,
            }),
            rejectUnauthorized: false,
          });
  
          const data = await response.json();
          if (data.response) {
            setMessages((prevMessages) => {
              // Remove the warning message if it exists
              const filteredMessages = prevMessages.filter(msg => msg.type !== 'warning');
              return [...filteredMessages, { text: data.response, sender: 'bot', type: 'normal' }];
            });
            setSessionId(data.session_id);
          } else if (data.error) {
            console.error('Error generating response:', data.error);
            setMessages((prevMessages) => {
              // Remove the warning message if it exists
              const filteredMessages = prevMessages.filter(msg => msg.type !== 'warning');
              return [
                ...filteredMessages,
                {
                  text: "Er is een fout opgetreden bij het genereren van het antwoord. Probeer het opnieuw.",
                  sender: 'bot',
                  type: 'error',
                },
              ];
            });
          }
        } catch (error) {
          console.error('Error generating response:', error);
          setMessages((prevMessages) => {
            // Remove the warning message if it exists
            const filteredMessages = prevMessages.filter(msg => msg.type !== 'warning');
            return [
              ...filteredMessages,
              {
                text: "Er is een fout opgetreden bij het verbinden met de server. Probeer het later opnieuw.",
                sender: 'bot',
                type: 'error',
              },
            ];
          });
        } finally {
          setIsTyping(false);
          clearTimeout(warningTimeoutRef.current);
        }
      }
    }
  };  

  const handleFeedback = async (index, feedback) => {
    if (apiEndpoint !== 'testing') {
      try {
        await fetch(apiEndpoint + 'submit_feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            session_id: sessionId,
            feedback,
          }),
          rejectUnauthorized: false,
        });
        console.log(`Feedback for message ${index}: ${feedback}`);
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    } else {
      console.log(`Testing feedback for message ${index}: ${feedback}`);
    }
  };

  const handleResetSession = async () => {
    if (apiEndpoint === 'testing') {
      setMessages([
        {
          text: customization.welcomeMessage || "Welcome to the Testing Assistant. This is a test interface without a real API connection. How can I assist you?",
          sender: 'bot',
          type: 'welcome',
        },
      ]);
    } else {
      try {
        await fetch(apiEndpoint + 'reset_session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            session_id: sessionId,
          }),
          rejectUnauthorized: false,
        });
        setSessionId(null);
        setMessages([
          {
            text: customization.welcomeMessage,
            sender: 'bot',
            type: 'welcome',
          },
        ]);
      } catch (error) {
        console.error('Error resetting session:', error);
      }
    }
    setSelectedRole(null);
  };

  const handlePrivacyClick = () => {
    const privacyMessage = customization.privacyMessage;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: privacyMessage, sender: 'bot', type: 'privacy' },
    ]);
  };

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    const roleMessage = role.message || `You've selected the role: ${role.label}`;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: roleMessage, sender: 'bot', type: 'role_selection' },
    ]);
  };

  return (
    <>
      {!open && (
        <>
          <ChatFab
            onClick={handleDrawerOpen}
            customization={customization}
            showUnreadIndicator={showUnreadIndicator}
          />
          {isTypingIndicatorVisible && (
            <TypingIndicatorWrapper
              side={customization.openButtonSide}
              horizontalMargin={customization.openButtonHorizontalMargin}
              verticalMargin={customization.openButtonVerticalMargin}
              backgroundColor={customization.primaryBackgroundColor}
            >
              <TypingIndicator />
            </TypingIndicatorWrapper>
          )}
          {showPopupMessage && (
            <MessagePopup
              side={customization.openButtonSide}
              horizontalMargin={customization.openButtonHorizontalMargin}
              verticalMargin={customization.openButtonVerticalMargin}
              fabSize={56}
              backgroundColor={customization.primaryBackgroundColor}
              textColor={customization.primaryTextColor}
              fontFamily={customization.fontFamily}
              popupMessage={customization.popupMessage || 'Waar kan ik je mee helpen?'}
              onClose={() => setShowPopupMessage(false)}
              onClick={handlePopupClick}
            />
          )}
        </>
      )}
      <ChatDrawer
        open={open}
        onClose={handleDrawerClose}
        customization={customization}
        messages={messages}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onSendMessage={handleSendMessage}
        onFeedback={handleFeedback}
        isTyping={isTyping}
        roleOptions={customization.roleOptions}
        onRoleSelection={handleRoleSelection}
        selectedRole={selectedRole}
        onResetSession={handleResetSession}
        onPrivacyClick={handlePrivacyClick}
      />
    </>
  );
});

export default ChatInterface;
