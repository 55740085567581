import React from 'react';
import TypingIndicator from './TypingIndicator';
import { TypingBubbleContainer, TypingBubblePaper } from '../styles/TypingBubbleStyles';

const TypingBubbleComponent = ({ backgroundColor }) => (
  <TypingBubbleContainer>
    <TypingBubblePaper style={{ backgroundColor: backgroundColor }}>
      <TypingIndicator />
    </TypingBubblePaper>
  </TypingBubbleContainer>
);

export default TypingBubbleComponent;
