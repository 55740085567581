import React, { useState } from 'react';
import { Box, Typography, Menu, MenuItem } from '@mui/material';
import { Close as CloseIcon, MoreHoriz as MoreHorizIcon, Refresh as RefreshIcon, Security as SecurityIcon } from '@mui/icons-material';
import { ChatHeaderContainer, ChatHeaderLogo, ChatHeaderMenu, ChatHeaderClose } from '../styles/ChatHeaderStyles';

const ChatHeader = ({ assistantPicture, assistantName, color, backgroundColor, onClose, onReset, onPrivacyClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleResetClick = () => {
    onReset();
    handleMenuClose();
  };

  const handlePrivacyClick = () => {
    onPrivacyClick();
    handleMenuClose();
  };

  return (
    <ChatHeaderContainer style={{ backgroundColor: backgroundColor }}>
      <Box display="flex" alignItems="center">
        <ChatHeaderLogo src={assistantPicture} alt="logo" />
      </Box>
      <Typography variant="h6" style={{ textAlign: 'center', color: color, flex: 1 }}>
        {assistantName}
      </Typography>
      <ChatHeaderMenu onClick={handleMenuOpen} style={{ color: color }}>
        <MoreHorizIcon />
      </ChatHeaderMenu>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ zIndex: 99999 }}
      >
        <MenuItem onClick={handleResetClick}>
          <RefreshIcon style={{ marginRight: '8px' }} /> Reset gesprek
        </MenuItem>
        <MenuItem onClick={handlePrivacyClick}>
          <SecurityIcon style={{ marginRight: '8px' }} /> Privacy
        </MenuItem>
      </Menu>
      <ChatHeaderClose onClick={onClose} style={{ color: color }}>
        <CloseIcon />
      </ChatHeaderClose>
    </ChatHeaderContainer>
  );
};

export default ChatHeader;
