const getConfigByClientId = async (clientId) => {
  const configurations = [
    {
      clientId: 'leerKRACHT_assistent',
      apiEndpoint: 'https://lk-api.aivoorimpact.nl/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://i.imgur.com/t5eqocz.png',
        assistantName: 'leerKRACHT AI-assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik geef graag antwoord op al je vragen over Stichting leerKRACHT.\n\nVoordat je jouw vraag stelt, wil ik je erop wijzen dat ik een kunstmatige intelligentie ben. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://stichting-leerkracht.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen? `,
        privacyMessage: `Door met de leerKRACHT assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleOptions: null,
      },
    },
    {
      clientId: 'doorloopjes_assistent',
      apiEndpoint: 'https://dl-api.aivoorimpact.nl/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#fec91c',
        secondaryTextColor: '#000000',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://i.imgur.com/byJwY5T.png',
        assistantName: 'Doorloopjes Assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Welkom, ik ben de Doorloopjes-assistent. Ik ben hier om je te helpen bij het vinden van de juiste Doorloopjes en kan jou van informatie voorzien over evidence informed werken.',
        privacyMessage: `Door met de Doorloopjes Assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleOptions: null,
      },
    },
    {
      clientId: 'online_academie_assistent',
      apiEndpoint: 'https://oa-api.aivoorimpact.nl/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://i.imgur.com/Z61ZDld.png',
        assistantName: 'Online Academie assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik geef graag antwoord op al je vragen over de Online Academie van leerKRACHT.\n\nVoordat je jouw vraag stelt, wil ik je erop wijzen dat ik een kunstmatige intelligentie ben. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://leerkracht-academie.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen? `,
        privacyMessage: `Door met de Online Academie assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'right',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleOptions: [
          { label: 'Schoolcoach', value: 'schoolcoach', message: 'Welkom, schoolcoach! Waar kan ik je mee helpen?' },
          { label: 'Leraar/Overig', value: 'leerkracht', message: 'Welkom, leraar of overig! Waar kan ik je mee helpen?' },
          { label: 'Schoolleider', value: 'schoolleider', message: 'Welkom, schoolleider! Waar kan ik je mee helpen?' },
        ],
      },
    },
    {
      clientId: 'je_leefstijl_als_medicijn_assistent',
      apiEndpoint: 'https://jlam-api.aivoorimpact.nl/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://i.imgur.com/cDhbKBZ.png',
        assistantName: 'Lampie - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik ben Lampie. Ik geef graag antwoord op al je vragen over leefstijl (voeding, bewegen, slaap, etc.). Ik kan je geen medisch advies geven.\n\nIk maak gebruik van kunstmatige intelligentie voor het beantwoorden van jouw vraag. Komen we er samen niet uit? Dan kan je ook persoonlijk contact met ons opnemen.\n\nWaar kan ik je mee helpen?`,
        privacyMessage: `Door met Lampie te chatten, ga je akkoord met onze [privacyverklaring](https://jeleefstijlalsmedicijn.nl/privacyverklaring/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleOptions: null,
      },
    },
    {
      clientId: 'testing_assistant',
      apiEndpoint: 'http://localhost:8080/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://i.imgur.com/t5eqocz.png',
        assistantName: 'leerKRACHT AI-assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik ben Lampie. Ik geef graag antwoord op al je vragen over leefstijl (voeding, bewegen, slaap, etc.). Ik kan je geen medisch advies geven.\n\nIk maak gebruik van kunstmatige intelligentie voor het beantwoorden van jouw vraag. Komen we er samen niet uit? Dan kan je ook persoonlijk contact met ons opnemen.\n\nWaar kan ik je mee helpen?`,
        privacyMessage: `Door met de leerKRACHT assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleOptions: null,
      },
    },
  ];

  const config = configurations.find((config) => config.clientId === clientId);
  if (!config) {
    throw new Error(`Configuration not found for client ID: ${clientId}`);
  }
  return config;
};

export default getConfigByClientId;
