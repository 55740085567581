import React from 'react';
import { TextField } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { InputAreaContainer, MessageField, SendButton } from '../styles/InputAreaStyles';

const InputArea = ({ inputValue, onInputChange, onKeyPress, onSendMessage, buttonColor, backgroundColor, disabled }) => (
  <InputAreaContainer style={{ backgroundColor: backgroundColor }}>
    <MessageField
      label="Typ uw bericht"
      variant="outlined"
      value={inputValue}
      onChange={onInputChange}
      onKeyPress={onKeyPress}
      fullWidth
      disabled={disabled}
    />
    <SendButton onClick={onSendMessage} style={{ color: buttonColor }} disabled={disabled}>
      <SendIcon />
    </SendButton>
  </InputAreaContainer>
);

export default InputArea;
