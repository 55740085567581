import React from 'react';
import { Drawer } from '@mui/material';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import InputArea from './InputArea';
import ChatFooter from './ChatFooter';
import { StyledChatDrawer } from '../styles/ChatDrawerStyles';

const ChatDrawer = ({
  open,
  onClose,
  customization,
  messages,
  inputValue,
  onInputChange,
  onKeyPress,
  onSendMessage,
  onFeedback,
  isTyping,
  roleOptions,
  onRoleSelection,
  selectedRole,
  onResetSession,
  onPrivacyClick,
}) => (
  <StyledChatDrawer
    anchor={customization.openButtonSide}
    open={open}
    onClose={onClose}
    fontFamily={customization.fontFamily}
    horizontalMargin={customization.openButtonHorizontalMargin}
    verticalMargin={customization.openButtonVerticalMargin}
    ModalProps={{
      BackdropProps: { style: { backgroundColor: 'transparent' } },
    }}
    style={{ zIndex: 99998 }}
  >
    <ChatHeader
      assistantPicture={customization.assistantPicture}
      assistantName={customization.assistantName}
      color={customization.primaryTextColor}
      backgroundColor={customization.primaryBackgroundColor}
      onClose={onClose}
      onReset={onResetSession}
      onPrivacyClick={onPrivacyClick}
    />
    <MessageList
      messages={messages}
      backgroundColor={customization.messageListBackgroundColor}
      primaryBackgroundColor={customization.primaryBackgroundColor}
      primaryTextColor={customization.primaryTextColor}
      secondaryBackgroundColor={customization.secondaryBackgroundColor}
      secondaryTextColor={customization.secondaryTextColor}
      onFeedback={onFeedback}
      isTyping={isTyping}
      roleOptions={!selectedRole ? roleOptions : null}
      onRoleSelection={onRoleSelection}
      roleButtonStyles={{
        bgcolor: customization.primaryBackgroundColor,
        textcolor: customization.secondaryBackgroundColor,
        bordercolor: customization.secondaryBackgroundColor,
      }}
    />
    <InputArea
      inputValue={inputValue}
      onInputChange={onInputChange}
      onKeyPress={onKeyPress}
      onSendMessage={onSendMessage}
      buttonColor={customization.secondaryBackgroundColor}
      backgroundColor={customization.primaryBackgroundColor}
      disabled={roleOptions && !selectedRole}
    />
    <ChatFooter />
  </StyledChatDrawer>
);

export default ChatDrawer;
