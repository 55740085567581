import styled from '@emotion/styled';
import { Box, TextField, IconButton } from '@mui/material';

export const InputAreaContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
`;

export const MessageField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiOutlinedInput-input {
    border: none !important;
    margin-bottom: 0 !important;
    padding: 16.5px 14px !important;
    color: black !important;
  }
  .MuiOutlinedInput-notchedOutline {
    margin-bottom: 0 !important;
  }
`;

export const SendButton = styled(IconButton)`
  color: ${({ style }) => style.color};
  margin-left: 10px;
`;
