import React from 'react';
import { Typography } from '@mui/material';
import { StyledChatFab, UnreadIndicator } from '../styles/ChatFabStyles';

const ChatFab = ({
  onClick,
  customization,
  showUnreadIndicator,
}) => (
  <StyledChatFab
    onClick={onClick}
    side={customization.openButtonSide}
    horizontalMargin={customization.openButtonHorizontalMargin}
    verticalMargin={customization.openButtonVerticalMargin}
    color={customization.secondaryBackgroundColor}
    style={{ zIndex: 99997 }}
  >
    <img
      src={customization.assistantPicture}
      alt="Assistant"
      style={{ width: '100%', height: '100%', borderRadius: '50%' }}
    />
    {showUnreadIndicator && (
      <UnreadIndicator>
        <Typography variant="caption">1</Typography>
      </UnreadIndicator>
    )}
  </StyledChatFab>
);

export default ChatFab;
