import React from 'react';
import { TypingIndicatorContainer, Dot } from '../styles/TypingIndicatorStyles';

const TypingIndicator = () => (
  <TypingIndicatorContainer>
    <Dot />
    <Dot />
    <Dot />
  </TypingIndicatorContainer>
);

export default TypingIndicator;
