import React, { useState, useRef, useEffect } from 'react';
import { Typography, IconButton } from '@mui/material';
import { ThumbUp, ThumbDown, Error } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import TypingBubble from './TypingBubble';
import {
  MessageListContainer,
  MessageContainer,
  MessagePaper,
  FeedbackContainer,
  RoleButtonsContainer,
  RoleButton,
  ErrorMessage
} from '../styles/MessageListStyles';

const MessageList = ({
  messages,
  backgroundColor,
  primaryBackgroundColor,
  primaryTextColor,
  secondaryBackgroundColor,
  secondaryTextColor,
  onFeedback,
  isTyping,
  roleOptions,
  onRoleSelection,
  roleButtonStyles,
}) => {
  const [feedbackStates, setFeedbackStates] = useState({});
  const messageListRef = useRef(null);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (isTyping) {
      scrollToBottom();
    }
  }, [isTyping, messages]);

  const handleFeedback = (index, feedback) => {
    setFeedbackStates((prevStates) => ({
      ...prevStates,
      [index]: feedback,
    }));
    onFeedback(index, feedback);
  };

  return (
    <MessageListContainer ref={messageListRef} style={{ backgroundColor: backgroundColor }}>
      {messages.map((message, index) => {
        const feedbackState = feedbackStates[index] || null;

        return (
          <React.Fragment key={index}>
            <MessageContainer sender={message.sender}>
              {message.type === 'error' ? (
                <ErrorMessage>
                  <Error style={{ marginRight: '10px' }} />
                  {message.text}
                </ErrorMessage>
              ) : (
                <MessagePaper
                  sender={message.sender}
                  style={{
                    primaryBackgroundColor: primaryBackgroundColor,
                    primaryTextColor: primaryTextColor,
                    secondaryBackgroundColor: secondaryBackgroundColor,
                    secondaryTextColor: secondaryTextColor
                  }}
                >
                  {message.type === 'warning'}
                  <ReactMarkdown
                    children={message.text}
                    components={{
                      p: ({ node, ...props }) => (
                        <Typography variant="body1" {...props} style={{ marginBottom: '12px' }} />
                      ),
                      ul: ({ node, ...props }) => (
                        <Typography variant="body1" component="ul" {...props} style={{ marginBottom: '12px' }} />
                      ),
                      li: ({ node, ...props }) => (
                        <Typography variant="body1" component="li" {...props} style={{ marginBottom: '4px' }} />
                      ),
                      a: ({ node, children, ...props }) => (
                        <a {...props} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                          {children}
                          <span style={{ display: 'none' }}>{children}</span>
                        </a>
                      ),
                    }}
                  />
                  {message.sender === 'bot' && message.type === 'normal' && (
                    <FeedbackContainer>
                      <IconButton
                        size="small"
                        onClick={() => handleFeedback(index, 'positive')}
                        disabled={feedbackState === 'positive'}
                      >
                        {feedbackState !== 'negative' && <ThumbUp fontSize="small" />}
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleFeedback(index, 'negative')}
                        disabled={feedbackState === 'negative'}
                      >
                        {feedbackState !== 'positive' && <ThumbDown fontSize="small" />}
                      </IconButton>
                    </FeedbackContainer>
                  )}
                </MessagePaper>
              )}
            </MessageContainer>
            {message.type === 'welcome' && roleOptions && (
              <RoleButtonsContainer>
                {roleOptions.map((role) => (
                  <RoleButton
                    key={role.value}
                    onClick={() => onRoleSelection(role)}
                    bgcolor={roleButtonStyles.bgcolor}
                    textcolor={roleButtonStyles.textcolor}
                    bordercolor={roleButtonStyles.bordercolor}
                  >
                    {role.label}
                  </RoleButton>
                ))}
              </RoleButtonsContainer>
            )}
          </React.Fragment>
        );
      })}
      {isTyping && <TypingBubble backgroundColor={primaryBackgroundColor} />}
    </MessageListContainer>
  );
};

export default MessageList;
