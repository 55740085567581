import styled from '@emotion/styled';

export const TypingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Dot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333;
  margin: 0 2px;
  animation: typing 1s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes typing {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;
