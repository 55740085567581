import styled from '@emotion/styled';
import { Drawer } from '@mui/material';

export const StyledChatDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    position: fixed;
    font-family: ${({ fontFamily }) => fontFamily};
    max-width: 500px;
    max-height: 800px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    border: none;
    top: auto;
    ${({ anchor, horizontalMargin, verticalMargin }) => {
      const sideStyles = {
        left: `
          @media (min-width: 500px) {
            left: ${horizontalMargin}px;
          }
          @media (min-height: 800px) {
            bottom: ${verticalMargin}px;
          }
        `,
        right: `
          @media (min-width: 500px) {
            right: ${horizontalMargin}px;
          }
          @media (min-height: 800px) {
            bottom: ${verticalMargin}px;
          }
        `,
      };
      return sideStyles[anchor];
    }}
  }
`;
