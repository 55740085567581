import React from 'react';
import { Link } from '@mui/material';
import { StyledChatFooter } from '../styles/ChatFooterStyles';

const ChatFooter = () => (
  <StyledChatFooter>
    Powered by{' '}
    <Link
      href="https://aivoorimpact.nl"
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
      color="inherit"
    >
      <strong>AI voor Impact</strong>
    </Link>
  </StyledChatFooter>
);

export default ChatFooter;
