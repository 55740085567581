import React from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { MessagePopupContainer, CloseButton } from '../styles/MessagePopupStyles';

const MessagePopup = ({
  side,
  horizontalMargin,
  verticalMargin,
  fabSize,
  backgroundColor,
  textColor,
  fontFamily,
  popupMessage,
  onClose,
  onClick,
}) => (
  <MessagePopupContainer
    side={side}
    horizontalMargin={horizontalMargin}
    verticalMargin={verticalMargin}
    fabSize={fabSize}
    backgroundColor={backgroundColor}
    textColor={textColor}
    fontFamily={fontFamily}
    onClick={onClick}
  >
    {popupMessage}
    <CloseButton size="small" textColor={textColor} onClick={(e) => { e.stopPropagation(); onClose(); }}>
      <CloseIcon fontSize="inherit" />
    </CloseButton>
  </MessagePopupContainer>
);

export default MessagePopup;
